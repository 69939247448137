import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={'coming-soon.jpg'} className="App-logo" alt="logo" />
        <span>
          Coming soon...
        </span>
      </header>
    </div>
  );
}

export default App;